<script lang="ts" setup>

    const { $preview } = useNuxtApp()

</script>

<template>
    <div>
        
        <Header :hide="false" :landing="true"/>
        <slot />
        <LazyFooter />
        <LazyNewPreview v-if="$preview" />

    </div>
</template>